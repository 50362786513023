import * as React from "react";
import * as z from "zod";
import axios from "axios";
import {useQuery, useMutation} from "react-query";
import {useNavigate} from "react-router-dom";
import {
    LinearProgress,
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Snackbar,
    Paper,
    Tooltip,
    Checkbox,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp, Delete, Add, FileDownload, Edit} from "@mui/icons-material";
import MuiAlert, {AlertProps} from "@mui/material/Alert";

const uniqueFacture = z.object({
    '@id': z.string(),
    '@type': z.literal('Facture'),
    customer: z.string(),
    customerMatricule: z.number(),
    date: z.string(),
    datetext: z.string(),
    exported: z.boolean(),
    id: z.number(),//.uuid() il déconne
    instructor: z.string(),
    instructorMatricule: z.number(),
    lignes: z.array(z.object({
        '@id': z.string(),
        '@type': z.literal('Ligne'),
        article: z.string(),
        designation: z.string(),
        id: z.number(),
        position: z.number(),
        pu: z.string(),
        quantite: z.string(),
    })),
    nbStagiaires: z.number(),
    numFactSage: z.number(),
    session: z.string(),
    societe: z.string(),//z.literal ?
    totalht: z.string(),
});
type uniqueFacture = z.infer<typeof uniqueFacture>;
const checkedList: Map<String, boolean> = new Map(); //*line checked for export
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Row(props: { row: uniqueFacture; societe: string; alignement: string }) {
    const [openSnack, setOpenSnack] = React.useState(true);
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [checked, actionChecked] = React.useState(true);
    //! Les snacks sur la mutation se suppriment directement, car la ligne se supprime
    const mutationDelete = useMutation((id: string) => {
        setOpenSnack(false);
        setOpenSnack(true);
        return axios.delete(`/api/factures/${id}`);
    });
    const idCheckedList = (id: string, boolState: boolean) => {
        checkedList.set(id, boolState);
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };
    let navigate = useNavigate();
    return (
        <React.Fragment>
            {mutationDelete.isError ? (
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="error" sx={{width: "100%"}}>
                        Erreur lors de la supression de la facture.
                    </Alert>
                </Snackbar>
            ) : null}
            {mutationDelete.isSuccess ? (
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="success" sx={{width: "100%"}}>
                        La facture est bien supprimée !
                    </Alert>
                </Snackbar>
            ) : null}
            <TableRow sx={{"& > *": {borderBottom: "unset"}}}>
                <TableCell>
                    {props.alignement == "Not-exported" ? (
                        <Checkbox
                            color="secondary"
                            checked={checked}
                            onChange={() => {
                                actionChecked(!checked);
                                idCheckedList(String(row.id), !checked);
                            }}
                        />
                    ) : (
                        row.numFactSage
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.instructor}
                </TableCell>
                <TableCell align="left">{row.customer}</TableCell>
                <TableCell align="left">{row.session}</TableCell>
                <TableCell align="left">{new Date(row.date).toLocaleDateString('fr-FR')}</TableCell>
                <TableCell align="left">{row.totalht}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Bas de la facture
                                {props.alignement == "Not-exported" ? (
                                    <>
                                        <Tooltip title="Modifier" sx={{ml: "1%"}}>
                                            <IconButton
                                                onClick={() => {
                                                    window.confirm("Modifier la facture ?")
                                                        ? navigate(`/factures_modification/${row.id}/${props.societe}`)
                                                        : console.log("cancel");
                                                }}
                                            >
                                                <Edit color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : null}
                                <Tooltip title="Supprimer">
                                    <IconButton
                                        onClick={() => {
                                            window.confirm("Supprimer la facture ?") ? mutationDelete.mutate(String(row.id)) : console.log("cancel");
                                        }}
                                    >
                                        <Delete color="error"/>
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Article</TableCell>
                                        <TableCell>Désignation</TableCell>
                                        <TableCell align="right">PU</TableCell>
                                        <TableCell align="right">Quantité</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.lignes.map((ligne, index) => (
                                        <TableRow key={ligne["@id"]}>
                                            <TableCell component="th" scope="row">
                                                {ligne.article}
                                            </TableCell>
                                            <TableCell>{ligne.designation}</TableCell>
                                            <TableCell align="right">{ligne.pu}</TableCell>
                                            <TableCell align="right">{ligne.quantite}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const TableDataValidator = z.array(z.object({
    '@id': z.string(),
    '@type': z.literal('Facture'),
    customer: z.string(),
    customerMatricule: z.number(),
    date: z.string(),
    datetext: z.string(),
    exported: z.boolean(),
    id: z.number(),
    instructor: z.string(),
    instructorMatricule: z.number(),
    lignes: z.array(z.object({
        '@id': z.string(),
        '@type': z.literal('Ligne'),
        article: z.string(),
        designation: z.string(),
        id: z.number(),
        position: z.number(),
        pu: z.string(),
        quantite: z.string(),
    })),
    nbStagiaires: z.number(),
    numFactSage: z.number(),
    session: z.string(),
    societe: z.string(),//z.literal ?
    totalht: z.string(),
}));
const TableData = (societe: string) => {
    return useQuery(
        ["get-tabledata"],
        async () => {
            //TODO régler l'ordre des lignes avec un filtre, l'ordre des factures aussi
            const res = await axios.get(`/api/factures?societe=${societe}`).then((response) => response.data['member']);
            TableDataValidator.parse(res).forEach((element) => {
                if (checkedList.get(String(element.id)) == null) {
                    if (element.exported == false) {
                        checkedList.set(String(element.id), true);
                    }
                }
            });
            return TableDataValidator.parse(res);
        },
        {
            onError: (err) => {
                console.error("Fetch API error", err);
            },
        }
    );
};
export default function RenderTableData(props: { societe: string }) {
    const {isLoading, isError, data} = TableData(props.societe);
    const [alignment, setAlignment] = React.useState("Not-exported");
    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setAlignment(newAlignment);
    };
    const mutationTelechargement = useMutation(async (checkedList: Map<String, boolean>) => {
        const res = await axios.get(`/outil_formation/api/download/facture/${props.societe}`, {
            params: {facture: Object.fromEntries(checkedList)},
            responseType: 'blob',
            headers: {'Content-Type': 'text/plain; charset=windows-1252'}
        });
        const blob = new Blob([res.data], {type: 'text/plain;charset=windows-1252'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "Export_Facture_" + new Date().toJSON().slice(0, 10).replace(/-/g, "/") + ".txt";
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
    });
    let navigate = useNavigate();
    if (isLoading) return <LinearProgress/>;
    if (isError) return <div>Error</div>;
    if (data)
        return (
            <>
                <Box display="flex" justifyContent="left" alignItems="left">
                    <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange}
                                       aria-label="Platform">
                        <ToggleButton value="Not-exported">Non exporté</ToggleButton>
                        <ToggleButton value="exported">Exporté</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <TableContainer component={Paper} sx={{mt: "2%"}}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {alignment == "Not-exported" ? (
                                        <Tooltip title="Télécharger"
                                                 onClick={() => mutationTelechargement.mutate(checkedList)}>
                                            <IconButton aria-label="dlFacture">
                                                <FileDownload/>
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        "N°"
                                    )}
                                </TableCell>
                                <TableCell>Formateur</TableCell>
                                <TableCell align="left">Client</TableCell>
                                <TableCell align="left">Session</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Total HT</TableCell>
                                <TableCell>
                                    {alignment == "Not-exported" ? (
                                        <Tooltip title="Ajouter">
                                            <IconButton aria-label="créer facture" size="small"
                                                        onClick={() => navigate(`/formateurs/${props.societe}`)}>
                                                <Add/>
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alignment == "Not-exported"
                                ? data.map((facture) =>
                                    facture.exported ? null :
                                        <Row key={facture.id} row={facture} societe={props.societe}
                                             alignement={alignment}/>
                                )
                                : data.map((facture) =>
                                    facture.exported ?
                                        <Row key={facture.id} row={facture} societe={props.societe}
                                             alignement={alignment}/> : null
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    return null;
}
